import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

const Services = ({ h2, h5, CTA }) => {
  return (
    <div
      css={Servicestyle}
      id={"section-services"}
      style={{
        width: "100%"
      }}
    >
      {h2 !== undefined &&
      <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
      }
      {h5 !== undefined &&
      <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
      }
      <div id="services-content-ctn">
        <div className="service-ctn">
          <h4>data management</h4>

          <img src="/data-management.jpg" alt="Data management devices" />
          <div>
            <Link to="/data-management/">more details</Link>
            <Link to="/references/">see references</Link>
            <Link to="/contact-us/">talk to an expert</Link>
          </div>
        </div>
        <div className="service-ctn">
          <h4>biostatistics</h4>
          <img src="/biostatistics.jpg" alt="Biostatistics documents" />
          <div>
            <Link to="/biostatistics/">more details</Link>
            <Link to="/references/">see references</Link>
            <Link to="/contact-us/">talk to an expert</Link>
          </div>
        </div>
        <div className="service-ctn">
          <h4>quality assurance</h4>
          <img src="/quality-assurance.jpg" alt="Quality assurance checking" />
          <div>
            <Link to="/quality-assurance/">more details</Link>
            <Link to="/references/">see references</Link>
            <Link to="/contact-us/">talk to an expert</Link>
          </div>
        </div>
      </div>

      {CTA !== undefined &&
        <div>
          <p>
            {CTA}{" "}
            <Link to="/contact-us/">here</Link>
          </p>
        </div>
      }
    </div>
  )
}

export default Services

const Servicestyle = css`
  #section-services {
    padding-bottom: 5rem;
  }

  h2 {
    margin-top: 0;
  }

  .service-ctn h4 {
    color: #f5f5f5;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
  }

  p {
    color: #636672;
    text-align: center;
    margin-bottom: 0;
  }

  p > a {
    color: #636672;
  }

  #services-content-ctn img {
    cursor: pointer;
    width: 15.3624rem;
    height: 26.88rem;
    display: block;
  }

  #services-content-ctn {
    display: flex;
    justify-content: center;
    margin-bottom: 1.36rem;
    flex-wrap: wrap;

  }

  #services-content-ctn img {
    cursor: pointer;
    width: 15.3624rem;
    height: 26.88rem;
    display: block;
  }

  .service-ctn {
    position: relative;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
  }

  .service-ctn > div {
    position: absolute;
    width: 15.3624rem;
    height: 26.875rem;
    top: calc(3.75rem - 0.1875rem);
    left: calc(1.7rem - 0.1875rem);
    background-color: rgba(1, 7, 26, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1875rem solid #d7e0ff;
    border-radius: 0.5rem;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .service-ctn > div:hover {
    opacity: 1;
  }

  .service-ctn > div > a {
    box-sizing: border-box;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border: 0.14rem solid white;
    width: 12rem;
    text-align: center;
    margin-bottom: 1rem;
    border-radius: 0.288rem;
    transition: background-color 0.5s, color 0.5s;
  }

  .service-ctn > div > a:hover {
    color: #020d31;
    background-color: white;
  }

  @media (max-width: 1023px) {
    #services-content-ctn {
      justify-content: space-around;
    }

    .service-ctn {
      margin-bottom: 3.5rem;
    }
  }



`