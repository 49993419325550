import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

const StudyProcess = ({ h2, h5 }) => {
  return (
    <div css={StudyProcessSectionStyles}
         style={{
           width: "100%"
         }}
    >

      {h2 !== undefined &&
      <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
      }
      {h5 !== undefined &&
      <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
      }

      <div id="health-progress-content-ctn">
        <div className="health-progress-ctn">
          <div>
            <svg id="microphone-icon" viewBox="0 0 102 135">
              <use href="/icons-sprite-map.svg#microphone-icon" />
            </svg>
          </div>
          <div>
            <h3>come as you are</h3>
            <p>Flexible Solutions Adapted To Your Study Specificities.</p>
          </div>
        </div>
        <div className="health-progress-ctn">
          <div>
            <svg id="document-icon" viewBox="0 0 122 116">
              <use href="/icons-sprite-map.svg#document-icon" />
            </svg>
          </div>
          <div>
            <h3>No need to do it twice</h3>
            <p>
              Health is demanding. No more health authorities rejection: your study design is up to date with all Good
              Practices.
            </p>
          </div>
        </div>
        <div className="health-progress-ctn">
          <div>
            <svg id="star-icon" viewBox="0 0 138 133">
              <use href="/icons-sprite-map.svg#star-icon" />
            </svg>
          </div>
          <div>
            <h3>No worries</h3>
            <p>No sleepless night because of a poor study design. 100% of our customers want to work with us again. </p>
          </div>
        </div>
        <div className="health-progress-ctn">
          <div>
            <svg id="people-icon" viewBox="0 0 120 108">
              <use href="/icons-sprite-map.svg#people-icon" />
            </svg>
          </div>
          <div>
            <h3>We care</h3>
            <p>
              Your doubts and questions matter. Our passionate team accompanies you through the whole study process.
            </p>
          </div>
        </div>
      </div>
      <Link className="cta-link" to="/contact-us/">
        Talk To A Trial Expert
      </Link>
      <hr />
    </div>
  )
}

export default StudyProcess

const StudyProcessSectionStyles = css`
  
  h5{
    margin-bottom: 1.32rem!important;
  }
  #section-study-process {
    margin-bottom: -5px;
    margin-top: -3px;
  }

  #section-study-process h2 {
    margin-top: 7rem;
  }


  #study-process-content-ctn hr {
    margin-bottom: 5rem;

  }

  #health-progress-content-ctn {
    max-width: 40.7478rem;
    margin: auto;
  }

  .health-progress-ctn {
    padding: 1rem 2rem 1rem 2rem;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    margin-bottom: 1.4rem;
    border-radius: 0.48rem;
  }

  .health-progress-ctn svg {
    margin-right: 2.876rem;
  }

  .health-progress-ctn h3 {
    color: #01166b;
    font-weight: 600;
    text-transform: uppercase;
  }

  #microphone-icon {
    width: 4.8967rem;
    height: 6.481rem;
  }

  #document-icon {
    width: 5.857rem;
    height: 5.568rem;
  }

  #star-icon {
    width: 6.625rem;
    height: 6.385rem;
  }

  #people-icon {
    width: 5.76rem;
    height: 5.184rem;
  }

  @media (max-width: 767px) {
    .health-progress-ctn {
      flex-direction: column;
      padding: 2rem;
      margin: 4vw;
    }

    .health-progress-ctn h3,
    .health-progress-ctn p {
      text-align: center;
    }

    #health-progress-content-ctn {
      max-width: 26rem;
    }

    .health-progress-ctn svg {
      margin: 0;
    }

    .health-progress-ctn p:last-of-type {
      margin-bottom: 0;
    }
  }
`
